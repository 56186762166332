import * as React from "react"
import { Field, Form, Formik } from "formik"
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"

import { MarketingContext } from "../components/CoreUI/MarketingContext"
import { convertFormValuesToGqlQuery } from "../components/CoreUI/utils/forms"
import { ContactFormProps as FormProps, ValidFormValues } from "./forms"
import FormResult from "./formResult"
import api from "../utils/api"
import { fireGtmEvent } from "../utils/gtm"
import type CoreUI from "../components/CoreUI/types"

type _ValidFormValues = ValidFormValues & { planningToBuild: boolean }

function validateName(name?: string): string {
  if (!name) {
    return `We need to know what to call you!`
  }
  return ``
}

function validateEmail(email?: string): string {
  if (!email) {
    return `Oops — an email address is required.`
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return `Hmm... This doesn't look like a valid email.`
  }
  return ``
}

function validatePhone(phone?: string): string {
  if (phone) {
    const strippedNumber = phone.replace(/[^0-9]/g, ``)
    if (strippedNumber.length < 10) {
      return `Hmm... This appears to be missing some digits`
    } else if (strippedNumber.length > 10) {
      return `Hmm... This appears to have too many digits.`
    } else {
      if (/^(\d)\1+$/g.test(strippedNumber)) {
        return `Nice try ;)`
      }
    }
  }
  return ``
}

function validatePlanningToBuild(value: boolean): string {
  if (value === false) {
    return `Due to high demand, we're only able to perform evaluations for individuals that plan to build within 12 months.`
  }
  return ``
}

function injectFormValues(msg: string, values: object): string {
  return Object.keys(values).reduce(
    (acc, cur) => acc.replace(`%${cur}%`, values[cur]),
    msg
  )
}

async function submitFormData(
  values: _ValidFormValues,
  actions: any,
  eventLabel
) {
  const { planningToBuild, ...rest } = values
  const query = convertFormValuesToGqlQuery(rest)
  const { data, status } = await api.post(``, { query })
  actions.setSubmitting(false)
  actions.setStatus(status)
  fireGtmEvent({
    eventName: `form_submission`,
    eventAction: `Form Submission`,
    eventCategory: values.form_name,
    eventLabel,
  })
}

function HomesiteEvaluationForm({
  automationId = 28,
  buttonColor = `red.500`,
  buttonId = ``,
  buttonText = `Submit`,
  context = null,
  errorHeader = `Oops!`,
  errorMessage = `Something went wrong. Please try again.`,
  eventLabel = `Homesite Evaluation`,
  name = `Homesite Evaluation`,
  successHeader = `Thanks, %name%!`,
  successMessage = `We'll contact you shortly to schedule your homesite evaluation.`,
  syncDeal = true,
}: FormProps): JSX.Element {
  const mkt = React.useContext<CoreUI.TMarketingContext>(MarketingContext)
  const normalizedContext = Array.isArray(context) ? context : [context]

  const utmParams = mkt.getUtmParamsObject()
  const contextualizedValues: _ValidFormValues = normalizedContext.reduce(
    (initialValues, context) => ({
      ...initialValues,
      [context.key]: context.value,
    }),
    {
      automation_id: automationId,
      email: ``,
      form_name: name,
      message: ``,
      name: ``,
      newsletter: false,
      phone: ``,
      planningToBuild: false,
      sync_deal: syncDeal,
      ...utmParams,
    }
  )

  return (
    <Formik
      initialValues={contextualizedValues}
      onSubmit={(values, actions) =>
        submitFormData(values, actions, eventLabel)
      }
    >
      {props => {
        return (
          <Form>
            {props.status ? (
              <FormResult
                body={injectFormValues(
                  props.status === 200 ? successMessage : errorMessage,
                  props.values
                )}
                h="465px"
                header={injectFormValues(
                  props.status === 200 ? successHeader : errorHeader,
                  props.values
                )}
                onClose={() => {
                  props.setStatus(null)
                  props.resetForm()
                }}
                type={props.status === 200 ? `success` : `error`}
              />
            ) : (
              <Flex
                align="flex-start"
                flexDir="row"
                flexWrap="wrap"
                justify="space-between"
              >
                <Box w="100%">
                  <Field name="name" validate={validateName}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                        isRequired
                      >
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <Input
                          {...field}
                          id="name"
                          placeholder="Name"
                          // size="lg"
                        />
                        <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email" validate={validateEmail}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel htmlFor="email" mt={4}>
                          Email Address
                        </FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="Email"
                          // size="lg"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phone" validate={validatePhone}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.phone && form.touched.phone}
                        isRequired
                      >
                        <FormLabel htmlFor="phone" mt={4}>
                          Phone Number
                        </FormLabel>
                        <Input
                          {...field}
                          id="phone"
                          placeholder="Phone Number"
                          // size="lg"
                        />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box w="100%">
                  <Field
                    name="planningToBuild"
                    validate={validatePlanningToBuild}
                  >
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={!form.values.planningToBuild}
                        isRequired
                      >
                        <Checkbox {...field} mt={-1}>
                          <Text mt={5}>
                            I meet the requirements to qualify for a free
                            homesite evaluation.
                          </Text>
                        </Checkbox>
                      </FormControl>
                    )}
                  </Field>
                  {/* <Field name="newsletter">
                  {({ field }) => (
                    <FormControl isInvalid={false}>
                      <Checkbox {...field} mt={4} value="newsletter">
                        Stay in touch with occassional updates from Sundog
                        Homes.
                      </Checkbox>
                    </FormControl>
                  )}
                </Field> */}
                  <Button
                    colorScheme={buttonColor.split(`.`)[0]}
                    id={buttonId}
                    isDisabled={
                      Object.keys(props.touched).length === 0 ||
                      !props.isValid ||
                      props.isSubmitting
                    }
                    isLoading={props.isSubmitting}
                    mt={6}
                    // size="lg"
                    type="submit"
                    w={{ base: `100%`, md: `200px` }}
                  >
                    {buttonText}
                  </Button>
                  <Text color="gray.500" fontSize="sm" mt={4}>
                    We'll never share this information with anyone.{` `}
                    <Link
                      as={GatsbyLink}
                      color="gray.700"
                      to="/privacy/"
                      variant=""
                    >
                      View our privacy policy
                    </Link>
                    .
                  </Text>
                </Box>
              </Flex>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default HomesiteEvaluationForm
